import React from 'react';

import { ContestProvider } from '../../ContestContext';

const PdfPage = props =>(
    <ContestProvider>
        { props.children }
    </ContestProvider>
)

export default PdfPage;

import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { useAuth } from './AuthContext';
import { config } from './Constants';
import { now } from 'moment';
import { toast } from 'react-toastify';
import { useRest } from './RestContext';
import useAsync from './useAsync';
import PageLoader from './components/Common/PageLoader';
import { useTranslation } from 'react-i18next';

const ContestContext = React.createContext()

const ContestProvider = ({children}) => {
    const { t } = useTranslation();

    const username = useAuth().username;
    const password = useAuth().password;
    const user = useAuth().user;
    const [connect, setConnect] = useState(0);

    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [startTimes, setStartTimes] = useState(new Map());
    const [endTimes, setEndTimes] = useState(new Map());
    const [activeContests, setActiveContests] = useState([]);
    //const [timeToNextSubmit, setTimeToNextSubmit] = useState();
    const [tasksTimeToNextSubmit, setTasksTimeToNextSubmit] = useState(new Map());
    const tasksTimeToNextSubmitRef = useRef(new Map());
    const changeTaskTimeToNextSubmit = (task, timeToNextSubmit) => {
        tasksTimeToNextSubmitRef.current.set(task, timeToNextSubmit);
        setTasksTimeToNextSubmit(tasksTimeToNextSubmitRef.current);
    }
    const [contestStarted, setContestStarted] = useState(false);
    const [contestFinished, setContestFinished] = useState(false);
    
    const [unreadAnnouncements, setUnreadAnnouncements] = useState(0);
    const [unreadQuestions, setUnreadQuestions] = useState(0);
    const [unansweredQuestions, setUnansweredQuestions] = useState(0);
    const [updateAnnouncements, setUpdateAnnouncements] = useState(0);
    const [updateQuestions, setUpdateQuestions] = useState(0);

    const [submissionsLeft, setSubmissionsLeft] = useState();
    const [updateSubmissions, setUpdateSubmissions] = useState(0);
    const [updateTaskSubmissions, setUpdateTaskSubmissions] = useState({"cnt": 0, "task": 0});
    const changeTaskSubmissions = (task) => {
        setUpdateTaskSubmissions({"cnt": updateTaskSubmissions.cnt + 1, "task": task});
    }
    const [isUnrestricted, setIsUnrestricted] = useState(false);

    const [updateContest, setUpdateContest] = useState(0);
    const [updateTasks, setUpdateTasks] = useState({"cnt": 0, "task": 0});
    const changeTask = (task) => {
        setUpdateTasks({"cnt": updateTasks.cnt + 1, "task": task});
    }
    const [updateNewTask, setUpdateNewTask] = useState(0);
    const [updateConfiguration, setUpdateConfiguration] = useState(0);

    const json = useRest().json;
    const { value: init } = useAsync(json, `init`, []);
    const { value: contests } = useAsync(json, `contests/names`, []);
    const { value: tasks } = useAsync(json, `tasks/ids`, [updateNewTask]);
    
    // const [stompClient, setStompClient] = useState();

    useEffect(() => {
        if (!startTime) return;
    
        if (startTime <= now()) {
            if (!contestStarted) setContestStarted(true);
        }
        else {
            if (contestStarted) setContestStarted(false);
            setTimeout(function() {
                setContestStarted(true);
            },
            Math.max(Math.min(startTime-now(), 2147483647), 0));
        }
    }, [startTime]);
    useEffect(() => {
        if (!startTime) return;
        
        if (startTime <= now() && !contestStarted) {
            setContestStarted(true);
        } else if (startTime > now() && contestStarted) {
            setContestStarted(false);
        }
    }, [contestStarted]);

    useEffect(() => {
        if (!endTime) return;
        
        if (endTime <= now()) {
            if (!contestFinished) setContestFinished(true);
        }
        else {
            if (contestFinished) setContestFinished(false);
            setTimeout(function() {
                setContestFinished(true);
            },
            Math.max(Math.min(endTime-now(), 2147483647), 0));
        }
    }, [endTime]);
    useEffect(() => {
        if (!endTime) return;
        
        if (endTime <= now() && !contestFinished) {
            setContestFinished(true);
        } else if (endTime > now() && contestFinished) {
            setContestFinished(false);
        }
    }, [contestFinished]);

    useEffect(() => {
        if (unreadQuestions !== 0) setUpdateQuestions(n => n+1);
    }, [unreadQuestions]);

    useEffect(() => {
        if (unreadAnnouncements !== 0) setUpdateAnnouncements(n => n+1);
    }, [unreadAnnouncements]);

    useEffect(() => {
        if (user.role !== 'USER' && user.role !== 'COORDINATOR' && user.role !== 'ADMIN' && user.role !== 'AUTHOR' && user.role !== 'SPECTATOR') return;
        if (!init || !contests || !tasks) return;

        if (user.role === 'USER') {
            setStartTime(now() + init.timeTillStart+200);
            setEndTime(now() + init.timeTillEnd);
        }

        // if (1 === 1) return;

        const sock = new SockJS(config.WS_URL);
        const stompClient = Stomp.over(sock);

        function showMessage(title, message, type) {
            toast(<div style={{wordBreak: 'break-word'}}>{title}<br />{message}</div>, {
                type: type,
                position: 'top-right'
            });
        }
        
        stompClient.connect(username, password,
            function() {
                //setStompClient(stompClient);
                if (user.role === 'USER' || user.role === 'COORDINATOR') {
                    stompClient.subscribe(`/topic/announcements/${user.contest}`, function (msg) {
                        const data = JSON.parse(msg.body);
                        setUnreadAnnouncements(u => u+1);
                        showMessage(data.topic, data.message, 'warning');
                    });
                    stompClient.subscribe(`/user/queue/announcements`, function (msg) {
                        const data = JSON.parse(msg.body);
                        if (data.action==='set') setUnreadAnnouncements(data.value);
                    });
                    stompClient.subscribe('/user/queue/questions', function (msg) {
                        const data = JSON.parse(msg.body);
                        if (data.action==='add') {
                            setUnreadQuestions(u => u + data.value);
                            showMessage(data.topic, data.message, 'info');
                        }
                        if (data.action==='set') setUnreadQuestions(data.value);
                        if (data.action==='update') setUpdateQuestions(n => n+1);
                    });
                    stompClient.subscribe('/user/queue/time', function (msg) {
                        const data = JSON.parse(msg.body);
                        if (data.timeTillStart) setStartTime(now() + data.timeTillStart+200);
                        if (data.timeTillEnd) setEndTime(now() + data.timeTillEnd);
                        if (data.task) changeTaskTimeToNextSubmit(data.task, now() + data.timeToNextSubmit);
                        //if (data.timeToNextSubmit) setTimeToNextSubmit(now() +  data.timeToNextSubmit);
                    });
                    stompClient.subscribe(`/user/queue/unrestricted`, function (msg) {
                        const data = JSON.parse(msg.body);
                        setIsUnrestricted(v => {
                            if (contestStarted === true && contestFinished === false) {
                                if (v === false && data.value === true) showMessage("Submissions", t("toast.lifted_restriction"), 'info');
                                if (v === true && data.value === false) showMessage("Submissions", t("toast.applied_restriction"), 'info');
                            }
                            return data.value;
                        });
                    });
                    stompClient.subscribe(`/topic/contest/${user.contest}`, function (msg) {
                        const data = JSON.parse(msg.body);
                        if (data.action === 'update') {
                            if (data.diffTimeStart) setStartTime(t => t + data.diffTimeStart);
                            if (data.diffTimeEnd) setEndTime(t => t + data.diffTimeEnd);
                            if (data.diffSubmissionAttempts) setSubmissionsLeft(s => s + data.diffSubmissionAttempts);
                            setUpdateContest(u => u+1);
                        }
                        if (data.action === 'task') changeTask(data.number);
                    });
                    stompClient.subscribe('/user/queue/user', function (msg) {
                        const data = JSON.parse(msg.body);
                        if (data.action === 'update') {
                            if (data.diffExtraTime) setEndTime(t => t + data.diffExtraTime*1000);
                        }
                    });
                }

                if (user.role === 'ADMIN' || user.role === 'AUTHOR' || user.role === 'SPECTATOR') {
                    stompClient.subscribe('/topic/contest', function (msg) {
                        const data = JSON.parse(msg.body);
                        if (!contests.includes(data.contest)) return ;
                        if (data.action === 'set') { /// no react update
                            setStartTimes((t) => {
                                t.set(data.contest, now() + data.timeTillStart);
                                return t;
                            });
                            setEndTimes((t) => {
                                t.set(data.contest, now() + data.timeTillEnd);
                                return t;
                            });
                        }
                        if (data.action === 'update') {
                            if (data.diffTimeStart) {
                                setStartTimes((t) => {
                                    const newTimes = new Map(t);
                                    newTimes.set(data.contest, newTimes.get(data.contest) + data.diffTimeStart);
                                    return newTimes;
                                });
                            }
                            if (data.diffTimeEnd) {
                                setEndTimes((t) => {
                                    const newTimes = new Map(t);
                                    newTimes.set(data.contest, newTimes.get(data.contest) + data.diffTimeEnd);
                                    return newTimes;
                                });
                            }
                        }
                    });
                }
            

                if (user.role === 'ADMIN' || user.role === 'AUTHOR') {
                    stompClient.subscribe(`/topic/submissions`, function (msg) {
                        const data = JSON.parse(msg.body);
                        if (data.action === 'update') {
                            if (!tasks.includes(data.task)) return ;
                            changeTaskSubmissions(data.task);
                        }
                    });
                    stompClient.subscribe('/topic/announcements', function (msg) {
                        const data = JSON.parse(msg.body);
                        if (!contests.includes(data.contest)) return ;
                        if (data.action === 'add') setUnreadAnnouncements(u => u+1);
                    });
                    stompClient.subscribe('/topic/questions', function (msg) {
                        const data = JSON.parse(msg.body);
                        if (!contests.includes(data.contest)) return ;
                        if (data.action === 'add') {
                            setUnansweredQuestions(u => u + data.value);
                            if (user.role === 'AUTHOR') {
                                showMessage(data.username, data.topic, 'info');
                            }
                        }
                        if (data.action === 'answered') setUnansweredQuestions(u => u - data.value);
                        setUpdateQuestions(n => n+1);
                    });
                    stompClient.subscribe('/user/queue/questions', function (msg) {
                        const data = JSON.parse(msg.body);
                        if (data.action==='set') setUnansweredQuestions(data.value);
                        setUpdateQuestions(n => n+1);
                    });
                    stompClient.subscribe('/topic/task', function (msg) {
                        const data = JSON.parse(msg.body);
                        if (data.action === 'add') setUpdateNewTask(u => u+1);
                        if (data.action === 'update') {
                            if (!tasks.includes(data.id)) return ;
                            changeTask(data.id);
                        }
                    });
                    stompClient.subscribe('/topic/configuration', function (msg) {
                        const data = JSON.parse(msg.body);
                        if (data.action === 'update') setUpdateConfiguration(u => u+1);
                    });
                }
                
                stompClient.subscribe('/user/queue/submissions', function (msg) {
                    const data = JSON.parse(msg.body);
                    /*if (data.action==='add') {
                        setSubmissionsLeft(s => s + data.value);
                        setUpdateSubmissions(s => s+1);
                    }*/
                    if (data.action==='set') {
                        setSubmissionsLeft(data.value);
                        setUpdateSubmissions(s => s+1);
                    }
                    if (data.action==='update') {
                        setUpdateSubmissions(s => s+1);
                    }
                });
                if (user.role === 'USER' || user.role === 'ADMIN' || user.role === 'AUTHOR' || user.role === 'SPECTATOR') stompClient.send("/app/connect", {}, "hello");
            },
            function stompFailure(error) {
                console.log(error,(new Date()).toString());
                setTimeout(() => setConnect(c => c+1), 10000);
            }
        );

        return () => {
            if (stompClient && stompClient.connected) stompClient.disconnect();
        }
    }, [init, contests, tasks, connect]);

    if (!init) return <PageLoader />;

    return (
        <ContestContext.Provider
            value={{
                startTime,
                endTime,
                startTimes,
                endTimes,
                activeContests,
                setActiveContests,
                contestStarted,
                contestFinished,
                //timeToNextSubmit,
                tasksTimeToNextSubmit,
                unreadAnnouncements,
                unreadQuestions,
                unansweredQuestions,
                updateQuestions,
                updateAnnouncements,
                submissionsLeft,
                updateSubmissions,
                updateTaskSubmissions,
                isUnrestricted,
                updateContest,
                updateTasks,
                updateConfiguration
            }}>
            {children}
        </ContestContext.Provider>
    )

}

const useContest = () => React.useContext(ContestContext);

export { ContestProvider, useContest }

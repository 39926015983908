import FileSaver from 'file-saver';
import React from 'react';
import { useAuth } from './AuthContext';
import { config } from './Constants';
import axios from "axios";

const RestContext = React.createContext()

const RestProvider = ({children}) => {

    const role = useAuth().userrole;
    const token = useAuth().usertoken;
    const logout = useAuth().logout;

    const json = async (url) => {
        const response = await sendRequest(url, "json");
        return await response.json();
    }

    const blob = async (url) => {
        const response = await sendRequest(url, "blob");
        return await response.blob();
    }

    const download = async (url) => {
        const response = await sendRequest(url, "blob");
        const content = response.headers.get('content-disposition');
        if (!content) return ;
        const filename = decodeURI(content.split("filename=")[1]);
        const blob = await response.blob();
        FileSaver.saveAs(new Blob([blob], { type: 'application/octet-stream' }), filename);
    }

    const post = async (url, formData) => {
        const response = await sendPostRequest(url, formData);
        return await response.json();
    }

    const postWithProgress = async (url, formData, setProgress) => {
        const response = await sendPostRequestWithProgress(url, formData, setProgress);
        return await response.data;
    }

    const put = async (url, formData) => {
        const response = await sendPutRequest(url, formData);
        return await response.json();
    }
    
    const putWithProgress = async (url, formData, setProgress) => {
        const response = await sendPutRequestWithProgress(url, formData, setProgress);
        return await response.data;
    }

    const delete1 = async (url, formData) => {
        const response = await sendDeleteRequest(url, formData);
        return await response.json();
    }

    const sendRequest = (url, type) => {
        return sendRequestWithToken(url, type);
    }
    
    const getURL = (url) => {
        return `${config.REST_URL}/${role}/${url}`
    }

    const sendRequestWithToken = (url, type) => {
        return fetch(getURL(url), {
            headers: {
                'Authorization': `Basic ${token}`
            },
            responseType: type
        }).then((response) => {
            if (response.status === 403) {
                logout();
            }
            return response;
        });
    }
    
    const sendPostRequest = (url, formData) => {
        return fetch(getURL(url), {
            method: 'POST',
            headers: {
                'Authorization': `Basic ${token}`
            },
            body: formData,
            responseType: 'json'
        }).then((response) => {
            if (response.status === 403) {
                logout();
            }
            return response;
        });
    }

    const sendPostRequestWithProgress = (url, formData, setProgress) => {
        return axios.post(getURL(url), formData, {
            headers: {
              "Authorization": `Basic ${token}`,
              "Content-Type": "multipart/form-data"
            },
            onUploadProgress: (progressEvent) => {
              const progress = 90*progressEvent.loaded/progressEvent.total;
              setProgress(progress);
            },
            onDownloadProgress: (progressEvent) => {
              const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
              setProgress(progress);
            },
        }).then((response) => {
            setProgress(100);
            if (response.status === 403) {
                logout();
            }
            return response;
        });
    }
    
    const sendPutRequest = (url, formData) => {
        return fetch(getURL(url), {
            method: 'PUT',
            headers: {
                'Authorization': `Basic ${token}`
            },
            body: formData,
            responseType: 'json'
        }).then((response) => {
            if (response.status === 403) {
                logout();
            }
            return response;
        });
    }
    
    const sendPutRequestWithProgress = (url, formData, setProgress) => {
        return axios.put(getURL(url), formData, {
            headers: {
              "Authorization": `Basic ${token}`,
              "Content-Type": "multipart/form-data"
            },
            onUploadProgress: (progressEvent) => {
              const progress = 90*progressEvent.loaded/progressEvent.total;
              setProgress(progress);
            },
            onDownloadProgress: (progressEvent) => {
              const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
              setProgress(progress);
            },
        }).then((response) => {
            setProgress(100);
            if (response.status === 403) {
                logout();
            }
            return response;
        });
    }
    
    const sendDeleteRequest = (url, formData) => {
        return fetch(getURL(url), {
            method: 'DELETE',
            headers: {
                'Authorization': `Basic ${token}`
            },
            body: formData,
            responseType: 'json'
        }).then((response) => {
            if (response.status === 403) {
                logout();
            }
            return response;
        });
    }

    return (
        <RestContext.Provider
            value={{
                json,
                blob,
                download,
                post,
                postWithProgress,
                put,
                putWithProgress,
                delete1,
                sendRequestWithToken
            }}>
            {children}
        </RestContext.Provider>
    )

}

const useRest = () => React.useContext(RestContext)

export { RestProvider, useRest }
